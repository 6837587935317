import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubCategory } from "../../../store/features/subCategorySlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createProduct } from "../../../store/features/productSlice";
import { toast } from "react-toastify";
import {
  getAllManufacter,
  getManufacter,
} from "../../../store/features/manufacterSlice";
import { getAllItemSubCategory } from "../../../store/features/itemSubCategorySlice";
import { getCategory } from "../../../store/features/categorySlice";
import Select from "react-select";
import { IoIosCloseCircle } from "react-icons/io";

const AddProduct = ({ openModal }) => {
  const dispatch = useDispatch();
  const subCategoryData =
    useSelector((state) => state.subcategory.getAllSubCategoryData) || [];
  const manufacturers =
    useSelector((state) => state.manufacter.getAllManufacterData) || [];

  const itemSubCategory =
    useSelector((state) => state.itemsubcategory.getAllItemSubCategoryData) ||
    [];
  const categoryData =
    useSelector((state) => state.category.categoryData) || [];
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [subcategory_id, setSubcategory_id] = useState("");
  const [subcategory_slug, setSubcategory_slug] = useState("");
  const [itemsubcategory_id, setItemSubcategory_id] = useState("");
  const [itmesubcategory_slug, setItemSubcategory_slug] = useState("");
  const [description, setDescription] = useState("");
  const [barcode, setBarcode] = useState("");
  const [manufacturerNumber, setManufacturerNumber] = useState("");
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("");
  const [instock, setInstock] = useState("");
  const [warranty, setWarranty] = useState("");
  const [discount, setDiscount] = useState("");
  const [photos, setPhotos] = useState([]);
  const [manufacturerId, setManufacturerId] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", name);
    data.append("category_id", category_id);
    data.append("category_slug", categorySlug);
    data.append("subcategory_id", subcategory_id);
    data.append("subcategory_slug", subcategory_slug);
    data.append("itemsubcategory_id", itemsubcategory_id);
    data.append("itemsubcategory_slug", itmesubcategory_slug);
    data.append("description", description);
    data.append("price", price);
    data.append("status", status);
    data.append("instock", instock);
    data.append("warranty", warranty);
    data.append("discount", discount);
    data.append("barcode", barcode);
    data.append("manufacter_id", manufacturerId);
    data.append("SKU", manufacturerNumber);

    for (let i = 0; i < photos.length; i++) {
      data.append("files", photos[i]);
    }

    dispatch(createProduct(data))
      .unwrap()
      .then(() => {
        resetForm();
        toast.success("Produkti u regjistrua me sukses");
        setError(null);
      })
      .catch((error) => {
        console.log(error);
        // setError(JSON.parse(error.message));
        toast.error("Diqka shkojë keqë!");
      });
  };

  const resetForm = () => {
    setCategorySlug("");
    setName("");
    setDescription("");
    setPrice("");
    setStatus("");
    setInstock("");
    setWarranty("");
    setDiscount("");
    setManufacturerNumber("");
    setBarcode("");
    setPhotos([]);
    if (editorInstance) {
      editorInstance.setData("");
    }
    document.getElementById("photoInput").value = null;
  };

  const handleOnChangeCat = (e) => {
    const { description, id } = JSON.parse(e.value);

    setCategory_id(id);
    setCategorySlug(description);
  };
  const handleOnChangeSub = (e) => {
    const { slug, id } = JSON.parse(e.value);
    setSubcategory_id(id);
    setSubcategory_slug(slug);
  };

  const handleOnChangeManufacturer = (selectedOption) => {
    setManufacturerId(selectedOption.value);
  };
  const handleOnChangeItemSub = (e) => {
    const { slug, id } = JSON.parse(e.value);
    setItemSubcategory_id(id);
    setItemSubcategory_slug(slug);
  };

  const handlePhotoChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
    ];
    const validFiles = selectedFiles.filter((file) =>
      validFileTypes.includes(file.type)
    );
    if (validFiles.length !== selectedFiles.length) {
      toast.error("Please upload files in PNG, JPG, or SVG format.");
      return;
    } else {
      setPhotos(validFiles);
    }
  };

  const optionsCat = categoryData?.categories?.map((item) => ({
    value: JSON.stringify(item),
    label: `${item.name}`,
  }));
  const optionsSubCat = subCategoryData.map((item) => ({
    value: JSON.stringify(item),
    label: `${item.name} (${item.category_name})`,
  }));

  const optionsItemSubCat = itemSubCategory.map((item) => ({
    value: JSON.stringify(item),
    label: `${item.name} (${item.subcategory_name})`,
  }));
  const optionsManufacturer = manufacturers?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    dispatch(getCategory({ all: true }));
    dispatch(getAllItemSubCategory());
    dispatch(getAllSubCategory());
    dispatch(getAllManufacter());
  }, [dispatch]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
      <div
        className="relative bg-white rounded-lg shadow-lg p-6 overflow-y-auto"
        style={{ width: "600px", maxHeight: "90vh" }}
      >
        <div className="flex items-center justify-between">
          <h3 className="mb-1 text-xl font-normal text-center text-gray-500">
            Shto Produktin
          </h3>
          <button onClick={openModal}>
            <IoIosCloseCircle />
          </button>
        </div>
        <form
          autoComplete="off"
          onSubmit={onSubmit}
          className="space-y-5 overflow-y-auto"
        >
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Emri i produktit
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              placeholder="Emri i produktit"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {error && error.name && (
              <span className="text-red-500">{error.name}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Kategoria
            </label>
            <Select
              name="category"
              id="category"
              options={optionsCat}
              onChange={handleOnChangeCat}
              classNamePrefix="react-select"
              placeholder="Zgjidh njerin nga opsione"
            />
            {error && error.subcategory && (
              <span className="text-red-500">{error.subcategory}</span>
            )}
            {/* <select
              name="category"
              id="category"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              onChange={handleOnChangeCat}
              value={category_id}
            >
              <option value="" disabled>
                Zgjidh njerin nga opsione
              </option>
              {categoryData?.categories?.map((item) => (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.name}
                </option>
              ))}
            </select> */}
          </div>

          <div>
            <label
              htmlFor="subcategory"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Nënkategoria
            </label>
            <Select
              name="subcategory"
              id="subcategory"
              options={optionsSubCat}
              onChange={handleOnChangeSub}
              classNamePrefix="react-select"
              placeholder="Zgjidh njerin nga opsione"
            />
            {error && error.subcategory && (
              <span className="text-red-500">{error.subcategory}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="itemsubcategory"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Nënkategoria e nënkategoris
            </label>
            <Select
              name="itemsubcategory"
              id="itemsubcategory"
              options={optionsItemSubCat}
              onChange={handleOnChangeItemSub}
              classNamePrefix="react-select"
              placeholder="Zgjidh njerin nga opsione"
            />
            {error && error.itemsubcategory && (
              <span className="text-red-500">{error.itemsubcategory}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="manufacturer"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Prodhuesi
            </label>
            <Select
              name="manufacturer"
              id="manufacturer"
              options={optionsManufacturer}
              onChange={handleOnChangeManufacturer}
              classNamePrefix="react-select"
              placeholder="Zgjidh njerin nga opsione"
            />
            {error && error.manufacturer && (
              <span className="text-red-500">{error.manufacturer}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="barcode"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Barkodi
            </label>
            <input
              type="text"
              name="barcode"
              id="barcode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              placeholder="Barkodi"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
            />
            {error && error.barcode && (
              <span className="text-red-500">{error.barcode}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="manufacturerNumber"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              SKU
            </label>
            <input
              type="text"
              name="manufacturerNumber"
              id="manufacturerNumber"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              placeholder="SKU"
              value={manufacturerNumber}
              onChange={(e) => setManufacturerNumber(e.target.value)}
            />
            {error && error.SKU && (
              <span className="text-red-500">{error.SKU}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="price"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Cmimi
            </label>
            <input
              type="number"
              name="price"
              id="price"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              placeholder="Cmimi"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            {error && error.price && (
              <span className="text-red-500">{error.price}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="warranty"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Garancioni
            </label>
            <input
              type="number"
              name="warranty"
              id="warranty"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              placeholder="Garancioni"
              value={warranty}
              onChange={(e) => setWarranty(e.target.value)}
            />
          </div>

          <div>
            <label
              htmlFor="discount"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Cmimi i zbritjes
            </label>
            <input
              type="number"
              name="discount"
              id="discount"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              placeholder="Cmimi i zbritjes"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />
          </div>

          <div>
            <label
              htmlFor="instock"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Sasia e stokut
            </label>
            <input
              type="number"
              name="instock"
              id="instock"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              placeholder="Sasia"
              value={instock}
              onChange={(e) => setInstock(e.target.value)}
            />
            {error && error.instock && (
              <span className="text-red-500">{error.instock}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Statusi
            </label>
            <select
              name="status"
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
            >
              <option value="" disabled>
                Zgjidh njerin nga opsione
              </option>
              <option value="1">PO</option>
              <option value="0">JO</option>
            </select>
            {error && error.status && (
              <span className="text-red-500">{error.status}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Pëshkrimi
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onReady={(editor) => {
                setEditorInstance(editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
              config={{
                height: "300px", // Set the desired height here
              }}
            />
            {error && error.description && (
              <span className="text-red-500">{error.description}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="photos"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Fotot
            </label>
            <input
              type="file"
              id="photoInput"
              onChange={handlePhotoChange}
              multiple
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
            />
          </div>

          <div className="flex justify-center mt-5">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Shto Produktin
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
