import { configureStore } from "@reduxjs/toolkit";

import toggleSlice from "./features/toggleSlice";
import authSlice from "./features/authSlice";
import userSlice from "./features/userSlice";
import categorySlice from "./features/categorySlice";
import subCategorySlice from "./features/subCategorySlice";
import productSlice from "./features/productSlice";
import orderSlice from "./features/orderSlice";
import productUserSlice from "./features/productUserSlice";
import itemSubCategorySlice from "./features/itemSubCategorySlice";
import manufacterSlice from "./features/manufacterSlice";
import statisticSlice from "./features/statisticSlice";
import detailsSlice from "./features/detailsSlice";
import discountSlice from "./features/discountSlice";
import bannerSlice from "./features/bannerSlice";
export const store = configureStore({
  reducer: {
    toggle: toggleSlice,
    auth: authSlice,
    user: userSlice,
    category: categorySlice,
    subcategory: subCategorySlice,
    product: productSlice,
    order: orderSlice,
    productUser: productUserSlice,
    itemsubcategory: itemSubCategorySlice,
    manufacter: manufacterSlice,
    statistic: statisticSlice,
    details: detailsSlice,
    discount: discountSlice,
    banner: bannerSlice,
  },
});
