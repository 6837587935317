import React, { useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { MdDeleteForever } from "react-icons/md";
import { CreateModalDetails, Pagination } from '../../../components'
import { toast } from 'react-toastify';
import { deleteSpecification, getSpecification, showDetailsFilter } from '../../../store/features/detailsSlice';

const Details = () => {
    const dispatch = useDispatch();
    const specificationData = useSelector((state) => state.details.specificationData) || [];
    const deleteSpecificationLoading = useSelector((state) => state.details.deleteSpecificationLoading)
    const createSpecificationLoading = useSelector((state) => state.details.createSpecificationLoading)
    const showDetailsFilerLoading = useSelector((state) => state.details.showDetailsFilterLoading)
    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(25);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };
    const filterSpecificationData = specificationData?.data?.filter((item) => {
        const name = item.specification_name && item.specification_name.toLowerCase().includes(searchTerm.toLowerCase());
        const categoryName = item.category_name && item.category_name.toLowerCase().includes(searchTerm.toLowerCase());
        return name || categoryName;
    });

    const indexOfLastItem = currentPage * 25;
    const indexOfFirstItem = indexOfLastItem - 25;
    const currentItems = filterSpecificationData?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(specificationData.total / itemsPerPage);

    const openModal = () => {
        setIsOpen(!isOpen)
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handelDeleteSpecification = (id) => {
        dispatch(deleteSpecification(id)).unwrap()
            .then(() => {
                toast.success("Detaji u fshi me suksës")
            })
            .catch(() => {
                toast.error('Diqka shkoi keqë')
            })
    }

    const handelShowDetailsInFilter = (id, value) => {
        const data = {
            value
        }
        dispatch(showDetailsFilter({ id, value: data }))
    }

    useEffect(() => {
        dispatch(getSpecification({ page: currentPage, limit: 25, searchTerm }))
    }, [deleteSpecificationLoading, createSpecificationLoading, showDetailsFilerLoading, currentPage, searchTerm])

    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 pb-3">
                <div className="relative mb-4 sm:mb-10 w-full sm:w-auto">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                        <FiSearch className="w-4 h-4 text-gray-800 dark:text-gray-400" />
                    </div>
                    <input
                        type="text"
                        id="table-search"
                        className="block p-2 pl-10 text-sm text-gray-900 border border-black rounded-lg w-full sm:w-80 bg-white focus:ring-blue-500 focus:border-blue-500 dark:border-dark dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Kërko këtu"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>
                <div className="w-full sm:w-auto">
                    <button className="w-full sm:w-auto bg-[#1A97F5] text-white font-bold py-2 px-4 rounded" onClick={openModal}>
                        Shto Detajet
                    </button>
                </div>
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg" style={{ borderRadius: 1 }}>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700  text-white uppercase bg-[#1A97F5] dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-4 py-4">
                                Emri
                            </th>
                            <th scope="col" className="px-4 py-4">
                                Emri i kategoris
                            </th>
                            <th scope="col" className="px-4 py-4">
                                Shfaq ne filtera
                            </th>
                            <th scope="col" className="px-4 py-4">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {specificationData?.specifications?.map((item) => {
                            return (
                                <tr
                                    key={item.id}
                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                >
                                    <td className="px-4 py-4">{item.name}</td>
                                    <td className="px-4 py-4">{item.category_name}</td>
                                    <td className='px-4 py-4'>{item.is_show_in_filter ? (
                                        <button onClick={() => handelShowDetailsInFilter(item.specification_id, 0)} className='px-4 py-2 font-semibold text-white rounded-lg shadow-md bg-red-500 hover:bg-red-700'>
                                            HIQ
                                        </button>
                                    ) : (
                                        <button onClick={() => handelShowDetailsInFilter(item.specification_id, 1)} className="px-4 py-2 font-semibold text-white rounded-lg shadow-md bg-blue-500 hover:bg-blue-700">
                                            VENDOS
                                        </button>
                                    )}</td>
                                    <td className="px-4 py-4 flex space-x-4">
                                        <button onClick={() => { handelDeleteSpecification(item.specification_id) }}>
                                            <MdDeleteForever />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
            {isOpen && <CreateModalDetails openModal={openModal} />}
        </div>
    )
}

export default Details
