import { IoIosCloseCircle } from "react-icons/io";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createBanner } from "../../../store/features/bannerSlice";

const CreateBannerModal = ({ openModal }) => {
  const dispatch = useDispatch();
  const [link, setLink] = useState("");
  const [photoMd, setPhotoMd] = useState(null);
  const [photoSm, setPhotoSm] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!link.trim()) {
      toast.error("Ju lutemi shtoni një link.");
      return;
    }

    if (!photoMd || !photoSm) {
      toast.error("Ju lutemi shtoni të dyja fotot.");
      return;
    }

    const formData = new FormData();
    formData.append("link", link);
    formData.append("photo_md", photoMd);
    formData.append("photo_sm", photoSm);

    try {
      await dispatch(createBanner(formData)).unwrap();
      toast.success("Banneri u shtua me sukses");
      openModal(); // Close modal after success
    } catch (error) {
      toast.error("Diçka shkoi keq, ju lutemi provoni përsëri.");
    }
  };

  const handlePhotoChange = (e, setPhoto) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
    ];

    if (!selectedFile) {
      toast.error("Ju lutemi zgjidhni një foto.");
      return;
    }

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error(
        "Ju lutemi ngarkoni skedarë në formatin PNG, JPG, JPEG ose SVG."
      );
      return;
    }

    setPhoto(selectedFile);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
      <div
        className="relative bg-white rounded-lg shadow-lg"
        style={{ width: "420px" }}
      >
        <div className="p-6">
          <div className="flex items-center justify-between">
            <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
              Shto banerin
            </h3>
            <button onClick={openModal}>
              <IoIosCloseCircle size={24} />
            </button>
          </div>
          <form autoComplete="off" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="link"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Link
              </label>
              <input
                type="text"
                name="link"
                id="link"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="photoMdInput"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Foto e mesme
              </label>
              <input
                type="file"
                id="photoMdInput"
                onChange={(e) => handlePhotoChange(e, setPhotoMd)}
                accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="photoSmInput"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Foto e vogël
              </label>
              <input
                type="file"
                id="photoSmInput"
                onChange={(e) => handlePhotoChange(e, setPhotoSm)}
                accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              />
            </div>

            <div className="flex items-center justify-center mt-5">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
              >
                Shto banerin
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateBannerModal;
