import { IoIosCloseCircle } from "react-icons/io";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createUser } from "../../../store/features/userSlice";
const CreateUserModal = ({ openModal }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        address: '',
        role: '',
        password: '',
    });

    const onSubmit = (e) => {
        e.preventDefault();
  
        dispatch(createUser(formData)).unwrap()
        .then(() => {
            toast.success("Pëdoruesi u regjistrua me sukses")
            setFormData({
                name: '',
                surname: '',
                email: '',
                phone: '',
                address: '',
                role: '',
                password: '',
            })
        })
        .catch((error) => {
            setError(error.message)
        });
       
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

   
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
            <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
            <div
                className="relative bg-white rounded-lg shadow-lg"
                style={{ width: "420px" }}
            >
                <div className="p-6 ">
                    <div className="flex items-center justify-between">
                        <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
                            Shto Pëdoruesit
                        </h3>
                        <button onClick={openModal}>
                            <IoIosCloseCircle />
                        </button>
                    </div>
                    <p className="text-red-500 text-center">{error}</p>
                    <form autoComplete="off" onSubmit={onSubmit}>
                        <div>
                            <label
                                htmlFor="name"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Emri
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Emri"
                                value={formData.name}
                                onChange={handleChange}
                            />

                        </div>

                        <div>
                            <label
                                htmlFor="surname"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Mbiemri
                            </label>
                            <input
                                type="text"
                                name="surname"
                                id="surname"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Surname"
                                value={formData.surname}
                                onChange={handleChange}
                            />

                        </div>

                        <div className="mt-3">
                            <label
                                htmlFor="name"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Email-i
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Email-i"
                                value={formData.email}
                                onChange={handleChange}
                            />

                        </div>

                        <div>
                            <label
                                htmlFor="phone"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                               Numri i telefoni
                            </label>
                            <input
                                type="number"
                                name="phone"
                                id="phone"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Numri i telefoni"
                                value={formData.phone}
                                onChange={handleChange}
                            />

                        </div>

                        <div>
                            <label
                                htmlFor="address"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                               Adresa
                            </label>
                            <input
                                type="text"
                                name="address"
                                id="address"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Adresa"
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-3">
                            <label
                                htmlFor="role"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Roli
                            </label>
                            <select
                                name="role"
                                id="role"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                value={formData.role}
                                onChange={handleChange}
                            >
                                <option value="" className="dark:text-white" disabled >Zgjidh njerin nga opsione</option>
                                <option value="admin" className="dark:text-white">Admin</option>
                                <option value="user" className="dark:text-white">Kompani</option>
                                <option value="guest" className="dark:text-white">Përdoruesë</option>
                            </select>
                        </div>

                        <div className="mt-3">
                            <label
                                htmlFor="password"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Password-i
                            </label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                       
                        <div className="flex items-center justify-center mt-5">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                            >
                               Shto Perdoruesin
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateUserModal;