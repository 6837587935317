import React from 'react'
import avatar from '../../assets/avatar.jpg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/features/authSlice'
const UserProfile = ({ data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem('token');
        dispatch(setUser())
        navigate('/login')
    }
    return (
        <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 z-40">
            <div className="flex justify-between items-center">
                <p className="font-semibold text-lg dark:text-gray-200">Të dhënat e përdoruesit</p>

            </div>
            <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
                <img
                    className="rounded-full h-24 w-24"
                    src={avatar}
                    alt="user-profile"
                />
                <div>
                    <p className="font-semibold text-xl dark:text-gray-200"> {data.name} {data.surname} </p>
                    <p className="text-gray-500 text-sm dark:text-gray-400">  {data.role}   </p>
                    <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {data.email} </p>
                </div>
            </div>
            <div>

            </div>
            <div className="mt-5">
                <button
                    type="button"
                    onClick={logout}
                    style={{ backgroundColor: '#1A97F5', color: 'white', borderRadius: 10  }}
                    className={` text-2xl p-3 w-full hover:drop-shadow-xl hover:bg-[#1A97F5]`}
                >
                    Çkyçu
                </button>
            </div>
        </div>

    )
}

export default UserProfile
