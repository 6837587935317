import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { Dashboard } from "../layouts";
import {
  Category,
  Login,
  User,
  UserUpdate,
  SubCategory,
  Product,
  AddProdcut,
  UpdateProduct,
  Order,
  ProductUser,
  AddProductUser,
  UpdateProductUser,
  ItemSubCategory,
  Manufacter,
  AddOrder,
  Statistic,
  DetailsOrder,
  Details,
  Cupons,
  Profile,
  Banners,
} from "../pages";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route
          path="/"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Statistic />
            </PrivateRoute>
          }
        />

        <Route
          path="/user"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <User />
            </PrivateRoute>
          }
        />

        <Route
          path="/manufacter"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Manufacter />
            </PrivateRoute>
          }
        />

        <Route
          path="/category"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Category />
            </PrivateRoute>
          }
        />

        <Route
          path="/user/:id"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <UserUpdate />
            </PrivateRoute>
          }
        />

        <Route
          path="/subcategory/:id"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <SubCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Product />
            </PrivateRoute>
          }
        />

        <Route
          path="/addProduct"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <AddProdcut />
            </PrivateRoute>
          }
        />

        <Route
          path="/updateProduct/:id"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <UpdateProduct />
            </PrivateRoute>
          }
        />

        <Route
          path="/order"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Order />
            </PrivateRoute>
          }
        />

        <Route
          path="/details"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Details />
            </PrivateRoute>
          }
        />

        <Route
          path="/details/order/:id"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <DetailsOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/addOrder"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <AddOrder />
            </PrivateRoute>
          }
        />

        <Route
          path="/user-products"
          element={
            <PrivateRoute allowedRoles={["user"]}>
              <ProductUser />
            </PrivateRoute>
          }
        />

        <Route
          path="/cupons"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Cupons />
            </PrivateRoute>
          }
        />

        <Route
          path="/itemSubCategory/:id"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <ItemSubCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="banners"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <Banners />
            </PrivateRoute>
          }
        />
        <Route
          path="/update-product/:id"
          element={
            <PrivateRoute allowedRoles={["user"]}>
              <UpdateProductUser />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute allowedRoles={["user"]}>
              <Profile />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default AppRoutes;
