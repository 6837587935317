import React from 'react';
import { FiSettings } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
  const toggle = useSelector((state) => state.toggle.isToggle);

  return (
    <div>
      <div className="flex relative bg-[#FAFBFB]">
        <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
          <button className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray">
            <FiSettings />
          </button>
        </div>
        {toggle ? (
          <div className="w-72 fixed sidebar bg-[#FFFFFF]  z-[100]">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg  z-[100]">
            <Sidebar />
          </div>
        )}

        <div
          className={
            toggle
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
            <Navbar />
          </div>
          
          <div >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
