import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { CreateSubCategoryModal, Pagination } from "../../../components";
import { MdDeleteForever } from "react-icons/md";
import { FaLink } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import {
  deleteSubCategory,
  getSubCategory,
} from "../../../store/features/subCategorySlice";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { urlConfig } from "../../../config";

const SubCategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const subCategoryData =
    useSelector((state) => state.subcategory.getSubCategoryData) || [];
  const createSubCategoryLoading = useSelector(
    (state) => state.subcategory.createSubCategoryLoading
  );
  const deleteSubCategoryLoading = useSelector(
    (state) => state.subcategory.deleteSubCategoryLoading
  );
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filterSubCategoryData = subCategoryData.filter((item) => {
    const name =
      item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
    const desc =
      item.description &&
      item.description.toLowerCase().includes(searchTerm.toLowerCase());
    return name || desc;
  });

  const indexOfLastItem = currentPage * 25;
  const indexOfFirstItem = indexOfLastItem - 25;
  const currentItems = filterSubCategoryData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filterSubCategoryData.length / 25);

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handelDeleteSubCategory = (id) => {
    dispatch(deleteSubCategory(id))
      .unwrap()
      .then(() => {
        toast.success("Nënkategoria u fshi me sukëses");
      })
      .catch(() => {
        toast.error("Diqka shkoi keqë");
      });
  };

  useEffect(() => {
    dispatch(getSubCategory(id));
  }, [createSubCategoryLoading, deleteSubCategoryLoading]);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex justify-between">
        <div className="relative mb-10">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
            <FiSearch className="w-4 h-4 text-gray-800 dark:text-gray-400" />
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-black rounded-lg w-80 bg-white focus:ring-blue-500 focus:border-blue-500  dark:border-dark dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Kërko këtu"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div>
          <button
            className="bg-[#1A97F5] text-white font-bold py-2 px-4 rounded"
            onClick={openModal}
          >
            Shto nënkategorinë
          </button>
        </div>
      </div>

      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700  text-white uppercase bg-[#1A97F5] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-4">
                Emri
              </th>
              <th scope="col" className="px-4 py-4">
                Përshkrimi
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => {
              return (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-4 py-4">{item.name}</td>
                  <td className="px-4 py-4">{item.description}</td>

                  <td className="px-4 py-4 flex space-x-4">
                    <Link to={`/itemSubCategory/${item.id}`}>
                      <FaLink />
                    </Link>
                    <button
                      onClick={() => {
                        handelDeleteSubCategory(item.id);
                      }}
                    >
                      <MdDeleteForever />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      {isOpen && <CreateSubCategoryModal openModal={openModal} id={id} />}
    </div>
  );
};

export default SubCategory;
