import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import { CreateBannerModal } from "../../../components";
import {
  deleteBanner,
  getAllBanners,
} from "../../../store/features/bannerSlice";
import { urlConfig } from "../../../config";

const Banners = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const bannerData =
    useSelector((state) => state.banner.getAllBannerData) || [];
  const createBannerLoading = useSelector(
    (state) => state.banner.createBannerLoading
  );
  const deleteBannerLoading = useSelector(
    (state) => state.banner.deleteBannerLoading
  );
  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteUBanner = (id) => {
    dispatch(deleteBanner(id));
  };
  useEffect(() => {
    dispatch(getAllBanners());
  }, [deleteBannerLoading, createBannerLoading]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 pb-3">
        <div className="w-full sm:w-auto">
          <button
            className="w-full sm:w-auto bg-[#1A97F5] text-white font-bold py-2 px-4 rounded"
            onClick={openModal}
          >
            Shto banerin
          </button>
        </div>
      </div>

      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700  text-white uppercase bg-[#1A97F5] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-4">
                Link
              </th>
              <th scope="col" className="px-4 py-4">
                Foto e mesme
              </th>
              <th scope="col" className="px-4 py-4">
                Foto e vogel
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {bannerData.map((item) => (
              <tr
                key={item.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-4 py-4">{item.link}</td>
                <td className="px-4 py-4">
                  <img src={`${urlConfig}/${item.photo_md}`} width={200} />
                </td>
                <td className="px-4 py-4">
                  <img
                    src={`${urlConfig}/${item.photo_sm}`}
                    width={200}
                    height={200}
                  />
                </td>
                <td className="px-4 py-4 flex justify-around">
                  <button
                    onClick={() => {
                      handleDeleteUBanner(item.id);
                    }}
                  >
                    <MdDeleteForever />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isOpen && <CreateBannerModal openModal={openModal} />}
    </div>
  );
};

export default Banners;
