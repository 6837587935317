import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getAllBanners = createAsyncThunk(
  "banner/getAllBanners",
  async () => {
    try {
      const response = await axiosInstance.get("/banner/getAllBanner");
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const createBanner = createAsyncThunk(
  "banner/createBanner",
  async (data) => {
    try {
      const response = await axiosInstance.post("/banner/createBanner", data);
      return response.data;
    } catch (error) {
      throw new Error(JSON.stringify(error.response.data));
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "banner/deleteBanner",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`/banner/deleteBanner/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const initialState = {
  getAllBannerData: null,
  getAllBannerLoading: false,
  getAllBannerError: null,
  createBannerData: null,
  createBannerLoading: false,
  createBannerError: null,
  deleteBannerData: null,
  deleteBannerError: null,
  deleteBannerLoading: false,
};
const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanners.rejected, (state, action) => {
        state.getAllBannerError = action.error.message;
        state.getAllBannerLoading = false;
      })
      .addCase(getAllBanners.fulfilled, (state, action) => {
        state.getAllBannerData = action.payload;
        state.getAllBannerLoading = false;
      })
      .addCase(getAllBanners.pending, (state) => {
        state.getAllBannerLoading = true;
        state.getAllBannerError = null;
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.createBannerError = action.error.message;
        state.createBannerLoading = false;
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.createBannerData = action.payload;
        state.createBannerLoading = false;
      })
      .addCase(createBanner.pending, (state) => {
        state.createBannerLoading = true;
        state.createBannerError = null;
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.deleteBannerError = action.payload.message;
        state.deleteBannerLoading = false;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.deleteBannerData = action.payload;
        state.deleteBannerLoading = false;
      })
      .addCase(deleteBanner.pending, (state) => {
        state.deleteBannerLoading = true;
        state.deleteBannerError = null;
      });
  },
});

export default bannerSlice.reducer;
