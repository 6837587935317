import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getManufacter = createAsyncThunk(
  "manufacter/getManufacter",
  async ({ page = 1, limit = 25, searchTerm = "", all = false }) => {
    try {
      const allParam = all ? `&all=true` : "";
      const response = await axiosInstance.get(
        `/manufacter/getManufacterName?page=${page}&limit=${limit}&search=${searchTerm}${allParam}`
      );
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Error fetching manufacturers"
      );
    }
  }
);

export const getAllManufacter = createAsyncThunk(
  "manufacter/getAllManufacter",
  async () => {
    try {
      const response = await axiosInstance.get("/manufacter/getAllManufacter");
      return response.data;
    } catch (error) {
      throw new Error(error.response.message.data);
    }
  }
);

export const deleteManufacter = createAsyncThunk(
  "manufacter/deleteManufacter",
  async (id) => {
    try {
      const response = await axiosInstance.delete(
        `/manufacter/deleteManufacterName/${id}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const createManufacter = createAsyncThunk(
  "manufacter/createManufacter",
  async (data) => {
    try {
      const response = await axiosInstance.post(
        "/manufacter/createManufacterName",
        data
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getManufacterByCatId = createAsyncThunk(
  "manufacter/getManufacterByCategoryId",
  async (id) => {
    try {
      const response = await axiosInstance.get(
        `/manufacter/getManufacterByCatId/${id}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const initialState = {
  getManufacterByCatIdData: null,
  getManufacterByCatIdLoading: false,
  getManufacterByCatIdError: null,
  createManufacterData: null,
  createManufacterLoading: false,
  createManufacterError: null,
  deleteManufacterData: null,
  deleteManufacterLoading: false,
  deleteManufacterError: null,
  manufacterData: null,
  manufacterLoading: false,
  manufacterError: null,
  getAllManufacterData: null,
  getAllManufacterLoading: false,
  getAllManufacterError: null,
};

const manufacterSlice = createSlice({
  name: "manufacter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllManufacter.rejected, (state, action) => {
        state.getAllManufacterError = action.error.message;
        state.getAllManufacterLoading = false;
      })
      .addCase(getAllManufacter.fulfilled, (state, action) => {
        state.getAllManufacterData = action.payload;
        state.getAllManufacterLoading = false;
      })
      .addCase(getAllManufacter.pending, (state) => {
        state.getAllManufacterError = null;
        state.getAllManufacterLoading = true;
      })
      .addCase(getManufacterByCatId.rejected, (state, action) => {
        state.getManufacterByCatIdError = action.error.message;
        state.getManufacterByCatIdLoading = false;
      })
      .addCase(getManufacterByCatId.fulfilled, (state, action) => {
        state.getManufacterByCatIdData = action.payload;
        state.getManufacterByCatIdLoading = false;
      })
      .addCase(getManufacterByCatId.pending, (state) => {
        state.getManufacterByCatIdLoading = true;
        state.getManufacterByCatIdError = null;
      })
      .addCase(createManufacter.rejected, (state, action) => {
        state.createManufacterError = action.error.message;
        state.createManufacterLoading = false;
      })
      .addCase(createManufacter.fulfilled, (state, action) => {
        state.createManufacterData = action.payload;
        state.createManufacterLoading = false;
      })
      .addCase(createManufacter.pending, (state) => {
        state.createManufacterLoading = true;
        state.createManufacterError = null;
      })
      .addCase(deleteManufacter.rejected, (state, action) => {
        state.deleteManufacterError = action.error.message;
        state.deleteManufacterLoading = false;
      })
      .addCase(deleteManufacter.fulfilled, (state, action) => {
        state.deleteManufacterData = action.payload;
        state.deleteManufacterLoading = false;
      })
      .addCase(deleteManufacter.pending, (state) => {
        state.deleteManufacterLoading = true;
        state.deleteManufacterError = null;
      })
      .addCase(getManufacter.rejected, (state, action) => {
        state.manufacterError = action.error.message;
        state.manufacterLoading = false;
      })
      .addCase(getManufacter.fulfilled, (state, action) => {
        state.manufacterData = action.payload;
        state.manufacterLoading = false;
      })
      .addCase(getManufacter.pending, (state) => {
        state.manufacterLoading = true;
        state.manufacterError = null;
      });
  },
});

export default manufacterSlice.reducer;
