import { CategoryCount } from "../../../components"
import UserCount from "../../../components/Statistic/UserCount"

const Statistic = () => {


  return (
    <div className="container flex flex-row flex-wrap justify-between mx-auto px-4 mt-20">
      <div>
        <UserCount />
      </div>
      <div>
        <CategoryCount />
      </div>
    </div>
  )
}

export default Statistic
