import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { updateStatusOrder } from '../../store/features/orderSlice';

const StatusButton = ({ order }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const statuses = [
        { label: 'Porositur', value: 'order' },
        { label: 'Konfirmuar', value: 'confirm' },
        { label: 'Deshtuar', value: 'cancel' },
        { label: 'Paguar', value: 'paid' },
        { label: 'Në pritje', value: 'pending' },
        { label: 'Realizuar', value: 'shipped' }
    ];

    const handleStatusChange = (status) => {
        setIsOpen(!isOpen);
        const data = {
            'status': status.value
        }
        dispatch(updateStatusOrder({id: order.id, data})).unwrap()
            .then(() => toast.success("Statusi eshte ndryshuar me sukses"))
            .catch(() => toast.error("Diqka shkoi keqë!"))
    };

    const currentStatusLabel = statuses.find(status => status.value === order.status)?.label || 'Status Unknown';

    return (
        <div className="relative">
            <button
                id="dropdownDividerButton"
                onClick={toggleDropdown}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
            >
                {currentStatusLabel}
            </button>

            {isOpen && (
                <div
                    id="dropdownDivider"
                    className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 absolute mt-2"
                >
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">
                        {statuses.map((item) => (
                            <li key={item.value}>
                                <button
                                    onClick={() => handleStatusChange(item)}
                                    className="block w-full py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    {item.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default StatusButton;

