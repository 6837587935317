import React from "react";
import { FaUsers, FaSitemap } from "react-icons/fa";
import {
  MdCategory,
  MdProductionQuantityLimits,
  MdFactory,
  MdDetails,
  MdOutlineDiscount,
} from "react-icons/md";
import { IoIosStats } from "react-icons/io";
import { GiPlagueDoctorProfile } from "react-icons/gi";
import { PiFlagBannerFoldBold } from "react-icons/pi";
export const links = [
  {
    title: "Menagjimi",
    links: [
      {
        name: "Statistikat",
        url: "",
        icon: <IoIosStats />,
        roles: ["admin", "user"],
      },
      {
        name: "Profili",
        url: "profile",
        icon: <GiPlagueDoctorProfile />,
        roles: ["user"],
      },
      {
        name: "Perdoruesit",
        url: "user",
        icon: <FaUsers />,
        roles: ["admin"],
      },
      {
        name: "Prodhuesit",
        url: "manufacter",
        icon: <MdFactory />,
        roles: ["admin"],
      },
      {
        name: "Kategorit",
        url: "category",
        icon: <MdCategory />,
        roles: ["admin"],
      },
      {
        name: "Produktet",
        url: "products",
        icon: <MdProductionQuantityLimits />,
        roles: ["admin"],
      },
      {
        name: "Porositë",
        url: "order",
        icon: <FaSitemap />,
        roles: ["admin"],
      },
      {
        name: "Produktet",
        url: "user-products",
        icon: <MdProductionQuantityLimits />,
        roles: ["user"],
      },
      {
        name: "Detajet",
        url: "details",
        icon: <MdDetails />,
        roles: ["admin"],
      },
      {
        name: "Kuponat",
        url: "cupons",
        icon: <MdOutlineDiscount />,
        roles: ["admin"],
      },
      {
        name: "Banerat",
        url: "banners",
        icon: <PiFlagBannerFoldBold />,
        roles: ["admin"],
      },
    ],
  },
];
