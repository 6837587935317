import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { links } from '../../../data/links';
import { MdOutlineCancel  } from 'react-icons/md'
import { setToggle } from '../../../store/features/toggleSlice';
const Sidebar = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth.userLogin) || '';
  const toggle = useSelector((state) => state.toggle.isToggle);
  const activeLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
     dispatch(setToggle());
    }
  };

  return (
    <div className='h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 '>
      {toggle && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <span>Main Logo</span>
            </Link>
            <button
                type="button"
                onClick={() => { dispatch(setToggle())}}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
          </div>
          <div className='mt-10'>
            {links.map((item) => (
              <div key={item.title}>
                <p className='text-gray-400 m-3 mt-4 uppercase'>
                  {item.title}
                </p>
                {item.links
                  .filter(link => link.roles.includes(auth.role))
                  .map((link) => (
                    <NavLink
                      to={`/${link.url}`}
                      key={link.name}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? '#1A97F5' : '',
                      })}
                      onClick={handleLinkClick}
                      className={({ isActive }) => isActive ? activeLink : normalLink}
                    >
                      {link.icon}
                      <span className='capitalize'>
                        {link.name}
                      </span>
                    </NavLink>
                  ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Sidebar;
