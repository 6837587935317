import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ allowedRoles, children }) => {
  const isAuthenticated = localStorage.getItem('token') && jwtDecode(localStorage.getItem('token'));
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  if (!allowedRoles.includes(isAuthenticated.role)) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default PrivateRoute