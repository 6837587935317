import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { Pagination } from "../../../components";
import { MdDeleteForever } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { formatDateString } from "../../../utils/time";
import { getAllProductUser } from "../../../store/features/productUserSlice";
import { deleteProduct } from "../../../store/features/productSlice";
import AddProductUser from "./AddProductUser";
const ProductUser = () => {
  const dispatch = useDispatch();
  const productData =
    useSelector((state) => state.productUser.prodcutUserData) || [];
  const deleteProductLoading = useSelector(
    (state) => state.product.deleteProductLoading
  );
  const createProductLoading = useSelector(
    (state) => state.productUser.createProductUserLoading
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const filterProductData = productData.filter((item) => {
    const name =
      item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
    return name;
  });

  const indexOfLastItem = currentPage * 25;
  const indexOfFirstItem = indexOfLastItem - 25;
  const currentItems = filterProductData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filterProductData.length / 25);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleDeleteProduct = (id) => {
    dispatch(deleteProduct(id))
      .unwrap()
      .then(() => toast.success("Produkti u fshi me sukses"))
      .catch(() => toast.error("Diqka shkoi keq!"));
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getAllProductUser());
  }, [deleteProductLoading, createProductLoading]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex justify-between ">
        <div className="relative mb-10">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
            <FiSearch className="w-4 h-4 text-gray-800 dark:text-gray-400" />
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-black rounded-lg w-80 bg-white focus:ring-blue-500 focus:border-blue-500  dark:border-dark dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Kërko këtu"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div>
          <button
            className="bg-[#1A97F5] text-white font-bold py-2 px-4 rounded"
            onClick={openModal}
          >
            Shto Prouktin
          </button>
        </div>
      </div>

      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700  text-white uppercase bg-[#1A97F5] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-4">
                Id
              </th>
              <th scope="col" className="px-4 py-4">
                Emri
              </th>
              <th scope="col" className="px-4 py-4">
                Cmimi
              </th>
              <th scope="col" className="px-4 py-4">
                Statusi
              </th>
              <th scope="col" className="px-4 py-4">
                Në stokë
              </th>
              <th scope="col" className="px-4 py-4">
                Barkodi
              </th>
              <th scope="col" className="px-4 py-4">
                SKU
              </th>
              <th scope="col" className="px-4 py-4">
                Data e krijimit
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr
                key={item.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-4 py-4">#{item.id}</td>
                <td className="px-4 py-4">{item.name}</td>
                <td className="px-4 py-4">{item.price}</td>
                <td className="px-4 py-4">
                  {item.status === 1 ? <p>24-48 orë</p> : <p>7-14 ditë</p>}
                </td>
                <td className="px-4 py-4">
                  {item.inStock === 1 ? <p>Po</p> : <p>Jo</p>}
                </td>
                <td className="px-4 py-4">{item.barcode}</td>
                <td className="px-4 py-4">{item.SKU}</td>
                <td className="px-4 py-4">
                  {formatDateString(item.created_at)}
                </td>
                <td className="px-4 py-4 flex justify-around">
                  <Link to={`/update-product/${item.id}`}>
                    <GrUpdate />
                  </Link>
                  <button
                    onClick={() => {
                      handleDeleteProduct(item.id);
                    }}
                  >
                    <MdDeleteForever />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      {isOpen && <AddProductUser openModal={openModal} />}
    </div>
  );
};

export default ProductUser;
