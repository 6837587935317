import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

export const getSubCategory = createAsyncThunk('subCategory/getSubCategory', async (id) => {
    try {
        const response = await axiosInstance.get(`/subcategory/getSubCategory/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const getAllSubCategory = createAsyncThunk('subCategory/getAllSubCategory', async () => {
    try {
        const response = await axiosInstance.get(`/subcategory/getSubCategory`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const createSubCategory = createAsyncThunk('subCategory/createSubCategory', async (data) => {
    try {
        const response = await axiosInstance.post('/subcategory/createSubCategory', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const deleteSubCategory = createAsyncThunk('subCategory/deleteSubCategory', async (id) => {
    try {
        const response = await axiosInstance.delete(`/subcategory/delete/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

const initialState = {
    getAllSubCategoryData: null,
    getAllSubCategoryLoading: false,
    getAllSubCategoryError: null,
    getSubCategoryData: null,
    getSubCategoryLoading: false,
    getSubCategoryError: null,
    createSubCategoryData: null,
    createSubCategoryLoading: false,
    createSubCategoryError: null,
    deleteSubCategoryData: null,
    deleteSubCategoryLoading: false,
    deleteSubCategoryError: null
}

const subCategorySlice = createSlice({
    name: "subcategory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllSubCategory.rejected, (state, action) => {
                state.getAllSubCategoryError = action.error.message;
                state.getAllSubCategoryLoading = false;
            })
            .addCase(getAllSubCategory.fulfilled, (state, action) => {
                state.getAllSubCategoryData = action.payload;
                state.getAllSubCategoryLoading = false;
            })
            .addCase(getAllSubCategory.pending, (state) => {
                state.getAllSubCategoryLoading = true;
                state.getAllSubCategoryError = null;
            })
            .addCase(deleteSubCategory.rejected, (state, action) => {
                state.deleteSubCategoryError = action.error.message;
                state.deleteSubCategoryLoading = false;
            })
            .addCase(deleteSubCategory.fulfilled, (state, action) => {
                state.deleteSubCategoryData = action.payload;
                state.deleteSubCategoryLoading = false;
            })
            .addCase(deleteSubCategory.pending, (state) => {
                state.deleteSubCategoryLoading = true;
                state.deleteSubCategoryError = null;
            })
            .addCase(getSubCategory.rejected, (state, action) => {
                state.getSubCategoryError = action.error.message;
                state.getSubCategoryLoading = false;
            })
            .addCase(getSubCategory.fulfilled, (state, action) => {
                state.getSubCategoryData = action.payload;
                state.getSubCategoryLoading = false;
            })
            .addCase(getSubCategory.pending, (state) => {
                state.getSubCategoryLoading = true;
                state.getSubCategoryError = null;
            })
            .addCase(createSubCategory.rejected, (state, action) => {
                state.createSubCategoryError = action.error.message;
                state.createSubCategoryLoading = false;
            })
            .addCase(createSubCategory.fulfilled, (state, action) => {
                state.createSubCategoryData = action.payload;
                state.createSubCategoryLoading = false;
            })
            .addCase(createSubCategory.pending, (state) => {
                state.createSubCategoryLoading = true;
                state.createSubCategoryError = null;
            })

    }
})

export default subCategorySlice.reducer;