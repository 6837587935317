import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getAllItemSubCategory = createAsyncThunk(
    'itemSubCategory/getAllItemSubCategory', 
    async () => {
        try {
            const response = await axiosInstance.get('/itemsubcategory/getItemSubCategory');
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

export const getItemSubCategoryById = createAsyncThunk(
    'itemSubCategory/getItemSubCategoryById', 
    async (id) => {
        try {
            const response = await axiosInstance.get(`/itemsubcategory/getItemSubCategory/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

export const createItemSubCategory = createAsyncThunk(
    'itemSubCategory/createItemSubCategory', 
    async (data) => {
        try {
            const response = await axiosInstance.post('/itemsubcategory/createItemSubCategory', data);
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

export const deleteItemSubCategory = createAsyncThunk(
    'itemSubCategory/deleteItemSubCategory', 
    async (id) => {
        try {
            const response = await axiosInstance.delete(`/itemsubcategory/deleteItemSubCategory/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

const initialState = {
    getAllItemSubCategoryData: null,
    getAllItemSubCategoryLoading: false,
    getAllItemSubCategoryError: null,
    getItemSubCategoryByIdData: null,
    getItemSubCategoryByIdLoading: false,
    getItemSubCategoryByIdError: null,
    createItemSubCategoryData: null,
    createItemSubCategoryLoading: false,
    createItemSubCategoryError: null,
    deleteItemSubCategoryData: null,
    deleteItemSubCategoryLoading: false,
    deleteItemSubCategoryError: null
};

const itemSubCategorySlice = createSlice({
    name: "itemsubcategory",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(deleteItemSubCategory.pending, (state) => {
                state.deleteItemSubCategoryLoading = true;
                state.deleteItemSubCategoryError = null;
            })
            .addCase(deleteItemSubCategory.fulfilled, (state, action) => {
                state.deleteItemSubCategoryData = action.payload;
                state.deleteItemSubCategoryLoading = false;
            })
            .addCase(deleteItemSubCategory.rejected, (state, action) => {
                state.deleteItemSubCategoryError = action.error.message;
                state.deleteItemSubCategoryLoading = false;
            })
            .addCase(createItemSubCategory.pending, (state) => {
                state.createItemSubCategoryLoading = true;
                state.createItemSubCategoryError = null;
            })
            .addCase(createItemSubCategory.fulfilled, (state, action) => {
                state.createItemSubCategoryData = action.payload;
                state.createItemSubCategoryLoading = false;
            })
            .addCase(createItemSubCategory.rejected, (state, action) => {
                state.createItemSubCategoryError = action.error.message;
                state.createItemSubCategoryLoading = false;
            })
            .addCase(getItemSubCategoryById.pending, (state) => {
                state.getItemSubCategoryByIdLoading = true;
                state.getItemSubCategoryByIdError = null;
            })
            .addCase(getItemSubCategoryById.fulfilled, (state, action) => {
                state.getItemSubCategoryByIdData = action.payload;
                state.getItemSubCategoryByIdLoading = false;
            })
            .addCase(getItemSubCategoryById.rejected, (state, action) => {
                state.getItemSubCategoryByIdError = action.error.message;
                state.getItemSubCategoryByIdLoading = false;
            })
            .addCase(getAllItemSubCategory.pending, (state) => {
                state.getAllItemSubCategoryLoading = true;
                state.getAllItemSubCategoryError = null;
            })
            .addCase(getAllItemSubCategory.fulfilled, (state, action) => {
                state.getAllItemSubCategoryData = action.payload;
                state.getAllItemSubCategoryLoading = false;
            })
            .addCase(getAllItemSubCategory.rejected, (state, action) => {
                state.getAllItemSubCategoryError = action.error.message;
                state.getAllItemSubCategoryLoading = false;
            });
    }
});

export default itemSubCategorySlice.reducer;
