import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { categoryCount } from '../../store/features/statisticSlice';
import { Bar } from 'react-chartjs-2';

const CategoryCount = () => {
    const dispatch = useDispatch();
    const categoryData = useSelector((state) => state.statistic.categoryCountData) || {};
  
    const chartData = {
      labels: ['Kategoritë', 'Nënkategoritë', 'Nënkategoritë e nënkategorive'],
      datasets: [
        {
          label: 'Kategoritë ',
          data: [categoryData.allCategory, categoryData.allSubCategory, categoryData.allItemSubCategory],
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        }
      ]
    };
  
  
    useEffect(() => {
        dispatch(categoryCount());
    }, [])
  
  return (
    <div className="App">
      <div style={{ width: '600px', margin: '0 auto' }}>
        <Bar data={chartData} />
      </div>
    </div>
  )
}

export default CategoryCount
